<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Edit Default {{str_title(default_earning_deductions.type)}}</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{name: 'earning-deductions'}">Earning Deductions</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{name: 'default-earning-deductions'}">
                        <span>Default Earning Deductions</span>
                    </router-link>
                </li>
                <li class="breadcrumb-item active">Edit Default {{str_title(default_earning_deductions.type)}}</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="col-md-8">
                <form v-on:keyup.enter="updateDefaultEarningDeductions($event)">
                     <div class="form-group row">
                        <label class="col-md-3">
                            {{str_title(default_earning_deductions.type)}} Name
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <input
                                type="text"
                                class="form-control"
                                aria-describedby="nameHelpInline"
                                v-model="default_earning_deductions.name"
                                @change="textTitleCase($event, 'name')"
                                autocomplete="off"
                            />
                        </div>
                    </div>

                    <div class="form-group row" v-if="payroll_formula">
                        <label class="col-md-3">
                            Formula
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                         <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-12">
                                    <span v-if="JSON.parse(payroll_formula.conditions).length>1">Default Formula : </span>{{ JSON.parse(payroll_formula.conditions)[JSON.parse(payroll_formula.conditions).length-1].formula }}
                                </div>
                            </div>
                            <div class="row mt-3" v-for="(condition, index) in JSON.parse(payroll_formula.conditions).slice(0, JSON.parse(payroll_formula.conditions).length-1)" :key="index">
                                <div class="col-md-12" v-for="(value,name,index2) in condition" :key="index2">
                                    <span>{{ str_title(name) }} {{ index+1 }}: {{ value }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row" v-if="default_earning_deductions.values.length > 0">
                        <label class="col-md-3">
                            <h4>Values</h4>
                        </label>
                    </div>

                    <div v-for="(value, slug) in default_earning_deductions.values" :key="slug" class="form-group row">
                        <label class="col-md-3">
                            {{ str_title(slug) }}
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <span v-if="default_earning_deductions.status == 1">
                                <input
                                type="number"
                                class="form-control"
                                aria-describedby="typeHelpInline"
                                v-model="default_earning_deductions.values[slug]"
                                autocomplete="off"
                            />
                            </span>
                            <span v-else>
                                {{ default_earning_deductions.values[slug] }}
                            </span>
                            
                        </div>
                    </div>

                    <div class="form-group row" style="float:right;">
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-primary text-right ma-10px"
                                v-on:click="updateDefaultEarningDeductions()"
                            >Update</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-secondary ma-10px"
                                @click="$router.go(-1)"
                            >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from "../../../errors/NotFound";
import default_error from "../../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            api_error: "",
            access: false,
            default_earning_deductions: {
                name: '',
                formula: '',
                type: '',
                values: ''
            },
            payroll_formula: null,
            alertDismissCountDown: 0,
            alertDismissSecs: 10
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_EARNING_DEDUCTIONS");
        this.getDefaultEarningDeductions();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        textTitleCase(event, key) {
            this.default_earning_deductions[key] = this.strTitleCase(event.target.value);
        },

        getPayrollFormula(id) {
            var query = this.getAuthHeaders();
            query['params'] = { id };
            this.axios.get(this.$api.get_payroll_formulas, query).then(response => {
                let result = response.data.data;
                this.payroll_formula = result;
            }).catch(err => {
                if (err) {
                    this.api_error = default_error.server_error;
                    this.showAlert();
                }
            });
        },
        
        getDefaultEarningDeductions() {
            this.startProgressBar();
            const query = this.getAuthHeaders();
            let input = {
                id : this.$route.params.id,
                type:this.$route.params.type,
            }
            query["params"] = input;
            this.axios.get(this.$api.get_default_earning_deductions, query)
                .then(response => {
                    this.default_earning_deductions.name = response.data.data.name;
                    this.default_earning_deductions.formula = response.data.data.payroll_formula.formula_id;
                    this.default_earning_deductions.type = response.data.data.type;
                    this.default_earning_deductions.values = JSON.parse(response.data.data.values);
                    this.default_earning_deductions.status = response.data.data.status;
                    this.getPayrollFormula(response.data.data.formula_id);
                    this.api_error = "";
                    this.updateProgressBar(true);
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        updateDefaultEarningDeductions() {
            this.startProgressBar();
            const query = this.getAuthHeaders();
            query["params"] = {};
            let params = {
                id: this.$route.params.id,
                name: this.default_earning_deductions.name,
                formula: this.default_earning_deductions.formula,
                type: this.default_earning_deductions.type,
                values: this.default_earning_deductions.values
            };
            params = this.deleteEmptyKeys(params);
            this.axios.post(this.$api.update_default_earning_deduction, params, query)
                .then(response => {
                    if (response.data.error) {
                        this.api_error = response.data.message;
                        this.showAlert();
                        this.updateProgressBar(true);
                        return;
                    }
                    this.$router.push({ name: "default-earning-deductions" });
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
    }
};
</script>
<style lang="scss">
@import "../../../../assets/css/custom.scss";
</style>
